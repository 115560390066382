var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "document-btn",
      style: { width: "40px", height: "40px" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.data.click.apply(null, arguments)
        },
      },
    },
    [
      _c("svg-icon", {
        attrs: { name: "ic-document", width: "20", height: "20" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }